.itemtaskscope {
  &__container {
    background-color: #3c3b53;
    color: white; }
  &list {
    &__container {
      background-color: #3c3b53; }
    &__title {
      background-color: #3c3b53;
      height: $navbar-height;
      padding: 0.5rem 1rem;
      font-weight: bold;
      cursor: pointer; }
    &__element {
      background-color: #43425d;
      padding: 0.5rem 1rem;
      margin-bottom: 3px;
      &:hover {
        cursor: pointer;
        background-color: #3c3b53; } }
    &__play {
      cursor: pointer;
      padding-right: 10px; } }
  &list {
    &__container__select {
      background-color: #3c3b53;
      position: fixed;
      height: calc(100% - #{$navbar-height});
      left: 0;
      z-index: 4;
      top: $navbar-height;
      width: $sidebar-width; } } }

.nav-tabs {
  margin: 0.75rem;
  border-bottom: 1px solid transparent;
  .nav-link.active, .nav-item.show .nav-link {
    border-color: transparent;
    border-bottom: 3px solid $primary;
    color: $primary; }
  .nav-link:hover, .nav-link:focus {
    border-color: transparent;
    border-bottom: 3px solid $primary; } }

.itemfilters {
  &__container {
    background-color: $white;
    .form-group {
      margin-bottom: 0.5rem; }
    label {
      margin-bottom: 0; } }
  &__accordion {
    background-color: #3c3b53;
    &__title {}
    &__element {
      background-color: #43425d; } } }

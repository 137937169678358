.splitviewsidebar {
  &__container {
    background-color: $gray-200;
    float: left;
    width: $sidebar-width;
    height: 100%;
    overflow: scroll;
    position: fixed;
    @include media-breakpoint-up(sm) {
      top: 0;
      z-index: 1000; } } }

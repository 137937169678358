.btn--float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }
#content {
  padding-top: $navbar-height; }

.nav-item {
  cursor: pointer; }

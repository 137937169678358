.itemquickdetails {
  &__container {
    padding: $spacer; }
  &__iconcontainer {
    background-color: #e8e7ff;
    border-radius: 20px;
    padding: 10px;
    width: 40px;
    height: 40px;
    margin: -0.5rem;
    &.highlight {
      background-color: #ffe7e7; } }
  &__itemparts {
    padding-inline-start: 0; } }

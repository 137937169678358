.navigationbar {
  z-index: 999;
  height: $navbar-height;
  position: fixed;
  width: 100%;
  transition: background-color 500ms linear;
  &__user {
    @include media-breakpoint-up(sm) {
      order: 3; }
    border-left: 1px solid $gray-300; }
  &__search {
    display: flex;
    flex-grow: 100; }
  &--sticky {
    background-color: $white; } }
.navplaceholder {
  height: 70px;
  width: 100%;
  background-color: $white;
  position: fixed;
  top: 0;
  z-index: 990; }
.navplaceholder:after {
  position: relative;
  height: 70px; }

.navbar-collapse {
  @include media-breakpoint-down(xs) {
    margin: 0.5rem -1rem;
    background-color: $white;
    padding: 1rem;
    .navbar-nav {
      .nav-link {
        color: rgba(0, 0, 0, 0.5); }
      .show > .nav-link, .active > .nav-link, .nav-link.show, .nav-link.active, .nav-link:focus {
        color: $dark; } } } }

.navbar-nav .dropdown-menu .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.navbar {
  padding: 0 1rem;
  @include media-breakpoint-up(md) {
    padding-left: calc(#{$sidebar-width} + 1rem); } }

.compilation {
  &__actions, &__loweractions {
    text-align: right;
    button {
      margin-left: $spacer; } }
  &__actions {
    margin-bottom: $spacer; }
  &__loweractions {
    margin-top: $spacer; }

  &__itemparttable {
    max-height: 60vH;
    overflow-y: scroll;
    scroll-behavior: smooth; } }


// font from CDN
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700")

///mixins
@import "styles/mixins/fontface";

//fonts
// @include fontFace('rockwell', './assets/fonts/rockwell')

// theme
@import "../node_modules/bootstrap/scss/functions";
@import "styles/bootstrapvariables";
@import "styles/variables";

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap";

// stylesheets containers
@import "styles/routes/Account";
@import "styles/routes/App";
@import "styles/routes/Dashboard";
@import "styles/routes/Designitem";
@import "styles/routes/Home";
@import "styles/routes/Item";
@import "styles/routes/ItemDisplay";
@import "styles/routes/Items";
@import "styles/routes/Laminate";
@import "styles/routes/LaminateDisplay";
@import "styles/routes/Laminates";
@import "styles/routes/Material";
@import "styles/routes/MaterialDisplay";
@import "styles/routes/Materials";
@import "styles/routes/ProductionItems";
@import "styles/routes/Root";

// Custom Overrides
@import "styles/overrides/nav-tabs";

//stylesheets components
@import "components/About/About";
@import "components/Can/Can";
@import "components/CompilationView/CompilationView";
@import "components/FieldInput/FieldInput";
@import "components/FileInput/FileInput";
@import "components/FlashAlert/FlashAlert";
@import "components/ItemFilters/ItemFilters";
@import "components/ItemForm/ItemForm";
@import "components/ItemPreview/ItemPreview";
@import "components/ItemQuickDetails/ItemQuickDetails";
@import "components/ItemTaskScope/ItemTaskScope";
@import "components/ItemView/ItemView";
@import "components/LaminateForm/LaminateForm";
@import "components/Login/Login";
@import "components/MaterialForm/MaterialForm";
@import "components/Navbar/Navbar";
@import "components/OfflineBar/OfflineBar";
@import "components/PasswordRecovery/PasswordRecovery";
@import "components/PasswordReset/PasswordReset";
@import "components/Register/Register";
@import "components/SplitviewContent/SplitviewContent";
@import "components/SplitviewSidebar/SplitviewSidebar";
@import "components/ZendeskBox/ZendeskBox";

body {
  margin: 0;
  padding: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.btn-circle-float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }

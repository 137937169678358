.itemview {
  &__container {
    padding-bottom: 50px; }
  &__filepreview, &__filepreview__modal {
    img {
      padding: 5px; }
    img.background {
      background-image: linear-gradient(45deg, #cccccc 25%, transparent 25%), linear-gradient(-45deg, #cccccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #cccccc 75%), linear-gradient(-45deg, #dddddd 75%, #cccccc 75%);
      background-size: 10px 10px;
      background-position: 0 0, 0 5px, 5px -5px, -5px 0px; } }
  &__filepreview {
    padding: 1rem;
    height: 100%;
    &__checkbox {
      float: right;
      &__label {
        height: 1rem;
        width: 1rem;
        margin-top: 0.25rem;
        background-image: linear-gradient(45deg, #cccccc 25%, transparent 25%), linear-gradient(-45deg, #cccccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #cccccc 75%), linear-gradient(-45deg, #dddddd 75%, #cccccc 75%);
        background-size: 10px 10px;
        background-position: 0 0, 0 5px, 5px -5px, -5px 0px; } }
    &__fullscreen {
      position: absolute;
      bottom: 0.5rem;
      right: 1.5rem;
      color: $gray-500; }
    &__modal__body {
      text-align: center; } }
  &__status {
    padding: $spacer;
    height: 100%;
    &__icon {
      width: 1.5rem; }
    &__part__color {
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid $gray-300; } }
  &__tabrawjson {
    pre {
      padding: 1rem;
      font-size: 0.7rem; } }
  &__detail {
    padding: $spacer;
    height: 100%;
    &__actions {
      float: right; } }
  &__tabsettings {
    &__form__itempartsinputs {
      &__label {
        margin-top: 2.5rem; }
      &__delete {
        margin-top: 2rem; } } } }

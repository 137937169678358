.itempreview {
  &:hover {
    text-decoration: none;
    .itempreview__container {
      background-color: $gray-300; } }
  &__container {
    background-color: $gray-100;
    border-bottom: 1px solid $gray-300;
    color: $body-color;
    overflow: hidden; }
  &__labels {
    .badge {
      margin: 0.25em; } }
  &__usedcolors {
    padding-inline-start: 0;
    li {
      display: flex;
      float: right;
      padding-right: 4px; }
    list-style: none;

    &__hexcodepreview {
      height: 1rem;
      width: 1rem; }

    &__colorcodelabel {
      color: $gray-500;
      width: 2.5rem; } }
  &__image {
    max-width: 100%;
    max-height: 100px; }
  &__title {
    margin-bottom: 0; }
  &__propertyicon {
    margin-right: 8px; }
  &__qtylabel {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    border: 1px solid $gray-300;
    background-color: rgba(255,255,255,0.5);
    font-size: 80%; }
  &__incompilation {
    position: absolute;
    left: 0;
    bottom: 0;
    left: 1rem;
    border: 1px solid $gray-300;
    background-color: rgba(255,255,255,0.5); } }
